import config from "../../config";
import * as enumes from "../../enum";
import { ROQ_ROUTING_KEY } from "../ReportingOfQuantities/constants";
import { TIME_REPORTING_ROUTING_KEY } from "../TimeReporting/constants";
import { SITE_INSTALLATION_ROUTING_KEY } from "../InstPlansPage/constants";

export const moduleList = [
    {
        name: config.electronicStaffRegistrationVersion === enumes.electronicRegistration.Team ? "control.Team_location_reporting" : "control.Electronic_staff_register_check_in_out",
        icon: "fa fa-users",
        action: "/modules/pl/",
        theme: "theme-1",
        isImplementedWithApp: false,
        uniqueKey: "ElectronicStaffRegistration",
        menu: []
    },
    {
        name: "control.Fiber_installation_plan",
        icon: "fa fa-home",
        action: "/modules/fbinstplan/",
        theme: "theme-2",
        isImplementedWithApp: false,
        uniqueKey: "FiberInstallationPlan",
        menu: []
    },
    {
        name: "dashMenu.Installation_plans",
        icon: "fa fa-broadcast-tower",
        action: SITE_INSTALLATION_ROUTING_KEY,
        theme: "theme-2",
        isImplementedWithApp: true,
        uniqueKey: "SiteInstallationPlan",
        menu: [
            { name: "label.Project", action: "projects" },
        ]
    },
    {
        name: "control.Registration_of_CAW",
        icon: "fa fa-exclamation-circle",
        action: "/modules/ata/",
        theme: "theme-2",
        isImplementedWithApp: false,
        uniqueKey: "ChangeRequest",
        menu: []
    },
    {
        name: "control.Registration_of_quantities",
        icon: "fa fa-tasks",
        action: ROQ_ROUTING_KEY,
        theme: "theme-2",
        isImplementedWithApp: true,
        uniqueKey: "ReportingOfQuantity",
        menu: [
            { name: "label.Team", action: "teams" },
            { name: "control.Code_list", action: "codelisttypes" }
        ]
    },
    {
        name: "control.Map",
        icon: "fa fa-globe",
        action: "/modules/map/",
        theme: "theme-2",
        isImplementedWithApp: false,
        uniqueKey: "Map",
        menu: []
    },
    {
        name: "label.My_tasks",
        icon: "fa fa-clock-o",
        action: "/modules/mytasks/",
        theme: "theme-3",
        isImplementedWithApp: false,
        uniqueKey: "MyTasks",
        menu: []
    },
    {
        name: "control.Project_documents",
        icon: "fa fa-folder-open",
        action: "/modules/storage/",
        theme: "theme-3",
        isImplementedWithApp: false,
        uniqueKey: "Storage",
        menu: []
    },
    {
        name: "control.E_Requisition",
        icon: "fa fa-shopping-cart",
        action: "/modules/requisition/",
        theme: "theme-3",
        isImplementedWithApp: false,
        uniqueKey: "E-Requisition",
        menu: []
    },
    {
        name: "control.MT_blade_usage",
        icon: "fa fa-dot-circle-o",
        action: "/modules/mtbladeusage/",
        theme: "theme-3",
        isImplementedWithApp: false,
        uniqueKey: "MicroTrencBladeUsage",
        menu: []
    },
    {
        name: "control.Time_reporting",
        icon: "fa fa-calendar",
        action: TIME_REPORTING_ROUTING_KEY,
        theme: "theme-3",
        isImplementedWithApp: true,
        uniqueKey: "TimeReporting",
        menu: []
    },
    {
        name: "control.Dashboard",
        icon: "fa fa-tachometer",
        action: "/modules/dashboard/",
        theme: "theme-4",
        isImplementedWithApp: false,
        uniqueKey: "Dashboard",
        menu: []
    }
];